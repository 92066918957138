import Nav from "../../components/nav/Nav";
import { Footer } from "../../components/footer/Footer";
import TeamMember from "../../components/team-member/TeamMember";
import amandaUrl from "../../resources/images/amanda.jpg"
import mayorvaughn from "../../resources/images/mayorvaughn.png"
import nilsurl from "../../resources/images/nils.png"
import matthooper from "../../resources/images/matthooper.jpeg"
import chrisUrl from "../../resources/images/chris.jpg"
import captainquint from "../../resources/images/captainquint.jpg"
import jakeUrl from "../../resources/images/jake.jpg"
import chiefbrody from "../../resources/images/chiefbrody.png"


function Team() {
    return (
        <div className="container">
            <Nav></Nav>

            <h1 className="py-2">Meet The Team!</h1>

            <TeamMember
                realImagePath={amandaUrl}
                crewImagePath={mayorvaughn}
                name="Amanda Johnson"
                role="Technician / Mayor Vaughn"
                bdayUrl="https://en.wikipedia.org/wiki/Freddie_Mercury"
                bdaytext="See Freddie Mercury (or Labor day 2016)"
            >
                <p>Amanda is a lifelong driver who brings a passion for being behind the wheel of a car. But not just any car - Volkswagens. Amanda's first car was a Golf TDI that was totaled due to an aggressive driving incident. Ever since that day, she swore she would never ever be one-upped on the road again and has been perfecting her craft of automotive excellence ever since. That way, when the day comes and she sees the six fingered driver who destroyed Volkswagen Golf TDI she can look at her in the eyes and say, “Hello, my name is Amanda. You totaled my first car. Prepare to die.”</p>
                <p>When Amanda is not driving, she enjoys turnings wrenches with her husband Nils and their two cats and dog who all live a quite life in the town of Northfield. On weekends (when not participating in Fast and Furious style autotmotive racing) she enjoys folk dancing and knitting.</p>

            </TeamMember>
            <TeamMember
                realImagePath={chrisUrl}
                crewImagePath={captainquint}
                name="Chris DeFlumeri"
                role="Driver / Technician / Captain Quint"
                bdayUrl="https://en.wikipedia.org/wiki/Pitbull_(rapper)"
                bdaytext="See Pitbull"
            >
                <p>A father, an engineer, and a man of many complicated and expensive hobbies, Chris is a Ford guy who also owns a Volkswagen so his wife can have a comfortable and reliable car.  Willing to leverage every single ounce of patience, energy, and pain tolerance possible, Chris likes to win.  If the thing breaks, fix it!
                    If you do it right the first time, you'll probably have to fix it less!  And if it's a big rusting pile of garbage, hit it with a hammer, some Fluid Film, some zip ties, some spark plugs, fix the leaks, and send it. </p>
                <p> When Chris isn't working on his cars his favorite activities are being a stay-at-home dad, fixing the latest thing to break on his house, or smoking a big piece of meat on his pellet smoker. </p>
                
            </TeamMember>

            <TeamMember
                realImagePath={jakeUrl}
                crewImagePath={chiefbrody}
                name="Jake Ciarlante"
                role="Driver / Technician / Captain Quint"
                bdayUrl="https://en.wikipedia.org/wiki/Rob_Huebel"
                bdaytext="See Rob Huebel"
            >
                <p>Jake is a pretty cool guy. Eh fixes cars and doesn't afraid of anything.</p>
            </TeamMember>


            <TeamMember
                realImagePath={nilsurl}
                crewImagePath={matthooper}
                name="Nils Johnson"
                role="Driver / Technician / Matt Hooper"
                bdayUrl="https://en.wikipedia.org/wiki/Lady_Gaga"
                bdaytext="See Lady Gaga"
            >
                <p>Nils is what his mom would call “The Engineering Type." Lets just leave it at that.</p>
                <p>When Nils isn't removing heavy rust scale from his old subaru that should be in a junk yard, he enjoys going on slow walks with his Border Collie.</p>
            </TeamMember>


            <Footer/>
        </div>
    );
}

export default Team;
