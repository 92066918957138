import { CarTable } from "../../components/car-table/CarTable";
import { Footer } from "../../components/footer/Footer";
import Nav from "../../components/nav/Nav";

function Car() {

  const headerItems = ['Item', 'Status', 'Value', 'hp'];
  const tableItems = [
    ['Buy Car', 'Done', 'dollars(500)', '+100'],
    ['Fix Car', 'Done', 'hours(many)', '+15'],
    ['Strip Interior', 'Done', 'hours(minimal)', '+2hp'],
    ['Chair', 'Purchased, needs install', 'safety(avg)', '+7'],
    ['Kill Switch', 'TODO', 'safety(max)', '+2'],
    ['Roll Cage', 'PENDING', 'safety(max)', '+90'],
    ['Fire Suppression', 'Bought', 'safety(🔥)', '+14'],
    ['Driver Gear', 'Still Shopping', 'fashion/safety(100)', '+10'],
    ['Crew Gear', 'TBD', 'fashion(100)', '+40'],

  ]

  return (
    <div className="container">
      <Nav></Nav>

      <div className="row team-member-well">
        <div className="col-12">

          <p>Tables are an excellent way to share information. Here is everything you may want to know about our car.</p>


          <CarTable
            headerItems={headerItems}
            items={tableItems}
          />

        </div>

      </div>

      <Footer/>

    </div>
  );
}

export default Car;
