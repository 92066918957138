import Nav from "../../components/nav/Nav";


function Events() {
  return (
    <div className="container">
      <Nav></Nav>

      <div className="team-member-well">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">New Hampshire Motor Speedway, Loudon NH</h2>
            <h3 className="text-center">Oct 19 - Oct 20, 2024</h3>
          </div>
        </div>

        <div className="row p-2" >
          <p>Come see us race! </p>
          <p>More info soon, or reach out if you can't wait</p>
        </div>

        <div className="row p-2">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2900.685733167778!2d-71.46314792345096!3d43.362683871171726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e271749cbc5f33%3A0x9bf69824bfaac9ed!2sNew%20Hampshire%20Motor%20Speedway!5e0!3m2!1sen!2sus!4v1726541985637!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

      </div>

     
      {/* <Footer/> */}
      
    </div>
  );
}

export default Events;
