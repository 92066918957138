import Nav from "./components/nav/Nav";
import { Footer } from "./components/footer/Footer";
import Jaws from "./resources/images/jaws.jpg"
import ItWorks from "./resources/images/itworks.jpg"


function HomePage() {
  return (
    <div className="container">
      <Nav></Nav>

      <div className="team-member-well p-2">
        <h2 className="text-center">Welcome! You found our very good website.</h2>

        <p>
          We are The Wicked Fast And Furious, and we (want to) race Lemons in Loudon on October 19th/20th!  Over the last several months, a determined team of men and women with various names amd skills took an old, rusty, mouse infested Mk IV Golf and turned it into the fastest slowest thing on 4 wheels. 

        <p>
          <div className="home-img-container">
          <img style={{maxWidth:"50%"}} src={ItWorks}></img>
          </div>
        </p>
          Our car is AMAZING. We have spent many, many weekends working making the car safe and fixing what we needed to be ready for the race. Our theme for this race is going to be centered around the 1975 classic thriller film <a href="https://en.wikipedia.org/wiki/Jaws_(film)">Jaws</a>.  
          Our team members will be making up residents of Amity Island and the crew of the Orca, and the car will be Bruce, the shark.  Though hopefully with less mechnical problems than the real Bruce so we can appear on the racetrack instead of racing as an intimidating musical theme... 
        </p>
      </div>

      <Footer/>
    </div>
  );
}

export default HomePage;
