function getTableHeader(str) {
    return(
        <th>{ str }</th>
    )
}

function getTableData(str) {
    return(
        <td>{ str }</td>
    )
}

function toRow(row) {
    return (
        <tr>{ row }</tr>
    )
}

export const CarTable = (props) => {
    const { headerItems, items } = props;
    const headerItemComponents = [];
    const itemsCompents = [];


    for(let i = 0; i < headerItems.length; i++) {
        headerItemComponents.push(getTableHeader(headerItems[i]));
    }

    for(let i = 0; i < items.length; i++) {
        let row = [];
        for(let j = 0; j < items[i].length; j++) {
            row.push(getTableData(items[i][j]))
        }
        itemsCompents.push(toRow(row));
    }

    return (
        <table>
            <tr>
                {headerItemComponents}
            </tr>
           {itemsCompents}
        </table>

    )
}