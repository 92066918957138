

const TeamMember = (props) => {
    const { realImagePath, crewImagePath, name, role, children, bdayUrl, bdaytext } = props;

    return (
        <div className="row team-member-well">
            <div className="col-8">
                <div>
                    <span><strong>Name: &nbsp;</strong></span>
                    <span>{name}</span>
                </div>
                <div>
                    <span><strong>Role: &nbsp;</strong></span><span>{role}</span>
                </div>
                <div>
                    <span><strong>Birthday: &nbsp;</strong></span><span><a href={bdayUrl}>{bdaytext}</a></span>
                </div>
                <div className="py-2">
                    <p>{children}</p>
                </div>
            </div>
            <div class="break"></div>
            <div className="col-4" style={{width:"100%"}}>
                <img className="img-fluid" style={{maxWidth:"50%"}} src={realImagePath}></img>
                <img className="img-fluid" style={{maxWidth:"50%"}} src={crewImagePath}></img>
            </div>
        </div>
    );
}

export default TeamMember;
