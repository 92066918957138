import { Link as RouterLink } from "react-router-dom";

function NavBtn(props) {
    const { text, url } = props;

    return (
    <span className="nav-btn">
         <RouterLink to={ url }>
            { text }
        </RouterLink>
    </span>
    );
  }
  
  export default NavBtn;
  